import React from "react";
import ReactDOM from "react-dom";
import "./style/index.css";

import Info from "./components/Info.js";
import About from "./components/About.js";
import Interests from "./components/Interests.js";
import Footer from "./components/Footer.js";

function App() {
    return (
        <>
            <br/>
            <div className="app--container">
                <Info />
                <About />
                <Interests />
                <Footer />
            </div>
            <br/>
        </>
    );
}

ReactDOM.render(<App />, document.getElementById("root"));