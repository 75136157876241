import avatar from "../images/avatar.jpg";
import "../style/info.css";

export default function Info() {
  return (
    <div className="info--container">
        <img src={avatar} alt="Avatar" className="avatar"/>
        <h1>Alex Janevik</h1>
        <h3>Computer Science Student</h3>
        <p className="info--website"><a href="https://www.janevik.net/" target="_blank" rel="noreferrer">janevik.net</a></p>

        <div className="info--buttons">
            <a href="mailto:ajanevik@gmail.com" target="_blank" rel="noreferrer" id="email--button">
            <i class="fa-solid fa-envelope"></i>
                <p>Email</p>
            </a>
            <a href="https://www.linkedin.com/in/alex-janevik/" target="_blank" rel="noreferrer" id="linkedin--button">
                <i class="fa-brands fa-linkedin"></i>
                <p>LinkedIn</p>
            </a>
        </div>
    </div>
  );
}