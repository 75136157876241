import "../style/footer.css";

export default function Footer() {
    return (
      <div className="footer--container">
          <a href="https://twitter.com/not_alexj" target="_blank" rel="noreferrer">
            <i class="fa-brands fa-square-twitter"></i>
          </a>
          
          <a href="https://www.facebook.com/profile.php?id=100010281818773" target="_blank" rel="noreferrer">
            <i class="fa-brands fa-square-facebook"></i>
          </a>

          <a href="https://www.instagram.com/alexjanevik/" target="_blank" rel="noreferrer">
            <i class="fa-brands fa-square-instagram"></i>
          </a>

          <a href="https://github.com/alexjanevik" target="_blank" rel="noreferrer">
            <i class="fa-brands fa-square-github"></i>
          </a>
      </div>
    );
  }